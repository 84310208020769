import React from 'react';

import enhancer from "./enhancer";

import { Link } from "react-router-dom";
import { Mapping } from "../../commons/router";

import { Header } from "antd/lib/layout";
import Links from "../UI/Links";


const makeHeaderStyle = (isPlain) => ({
    position: 'fixed', 
    zIndex: 1, 
    width: '100%', 
    color: isPlain ?  'black' : 'white', 
    backgroundColor: isPlain ?  '#fafafa' : 'transparent',
    transition: 'all 0.5s ease',
    borderColor: isPlain ? 'rgba(0,0,0, 0.1)' : 'transparent',
    borderWidth: '1px',
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
})

const HeaderComponent = ({ isPlain }) => (
         <Header style={makeHeaderStyle(isPlain)}>
            <div>
                <Link
                    to={Mapping.home.pathname}
                >
                
                    <img style={{
                        maxWidth: 46,
                        width: 46,
                        height: 46,
                        margin: '0 20px 0 0'
                    }} src="/assets/logo_rounded.png" alt="" />
                </Link>
                <Links to={Mapping.home.pathname} type={isPlain ? 'dark' : 'light'}>Huntrz.io</Links>
            </div>
            <div >
                {/* <Links type={isPlain ? 'dark' : 'light'} to={Mapping.register.pathname}>Rejoindre les huntrz</Links> */}
            </div>
         </Header>
);



export default enhancer(HeaderComponent);