import React from 'react';

import { 
    fields,
    enhancer 
} from "./enhancer";

import _map from "lodash/map";

import { FormsUI } from "../../UI";

import Button from "antd/lib/button";




const Bounty = ({ 
        values,
        errors,
        handleChange,
        handleSubmit 
}) => {
    return (
        <div style={{backgroundColor: '#cca', padding: 20, height: '100%'}}>
                {
                    _map(fields, (v, k) => (
                        <FormsUI.InputContainer key={k}>
                            <FormsUI.Input 
                                placeholder={v.placeholder}
                                name={k}
                                value={values[k]}
                                onChange={handleChange}
                            />
                            {errors[k] && <div id="feedback">{errors[k]}</div>}
                        </FormsUI.InputContainer>
                    ))
                }
              
            <Button onClick={handleSubmit}>Déposer un Bounty</Button>
        </div>
);
}



export default enhancer(Bounty);