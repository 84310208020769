import { withFormik } from "formik";
import { 
    compose 
} from "recompose";

import * as yup from "yup";

import _reduce from "lodash/reduce";

export const fields = {
        position: {
            initialValue: '',
            placeholder: 'React Ninja',
            validationSchema: yup.string().required().label('Position')
        },
        name: {
            initialValue: '',
            placeholder: 'John Hancock',
            validationSchema: yup.string().required().label('Name')
        },
        company: {
            initialValue: '',
            placeholder: 'Company Inc',
            validationSchema: yup.string().required().label('Company')
        },
        phone: {
            initialValue: '',
            placeholder: '0600000000',
            validationSchema: yup.string().required().label('Phone')
        },
        email: {
            initialValue: '',
            placeholder: 'johnhancock@company.com',
            validationSchema: yup.string().required().label('Email')
        }
    }

const validationSchema = yup.object().shape(_reduce(fields, (r, v, k) => ({...r, [k]: v.validationSchema}), {}))
const mapPropsToValues = () => _reduce(fields, (r, v, k) => ({...r, [k]: v.initialValue}), {});
const handleSubmit = (values, { setSubmitting }) => {
        console.log(values);
} 

const formConfig = {
    validationSchema,
    mapPropsToValues,
    handleSubmit
}

export const enhancer = compose(
    withFormik(formConfig)
)

