import React from 'react';

const typeform = `https://huntrz.typeform.com/to/gKtTrg?o=${window.location.href.includes('huntrz.io') ? 'web' : 'xxxxx'}`;

const Survey = () => (
    <div style={{
        position: 'absolute',
        top: '',
        height: 'calc(100vh)',
        width: '100%',
        background: '#37404A center center no-repeat'
    }}>
        <iframe style={{ paddingTop: 65 }} id="typeform-full" title="iframe" width="100%" height="100%" frameBorder={0} src={typeform} />
    </div>
)
export default Survey;
