const USER_LOGGED_IN = 'USER_LOGGED_IN';


const userLoggedIn = (payload) => {
    console.log(payload);
    return {
        type: USER_LOGGED_IN,
        payload
    }
}



export const authActions = {
    USER_LOGGED_IN,
    userLoggedIn
}