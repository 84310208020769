import React, { Fragment } from 'react';

import {
    Route,
    Switch,
} from 'react-router-dom';
import Mapping from "./mapping";

import _map from "lodash/map";

import { Header, Footer } from "../../components";


const AppRouter = ({
    location
}) => (
    <Fragment>
            <Header />
                <Switch >
                    {
                        _map(Mapping, (r, i) => <Route key={i} exact={r.exact} path={r.pathname} component={r.component}/>)
                    }

                </Switch>
        <Footer />
    </Fragment>
)


export default AppRouter;