import styled from "styled-components";

import AntInput from "antd/lib/input";

const InputContainer = styled.div`
    margin: 15px;
`
const Input = styled(AntInput)`
    width: 240px;
`

export default {
    InputContainer,
    Input
}