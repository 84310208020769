import React from 'react';

const terms = () => {
    return (
        <div>
           Hello terms 
        </div>
    );
};

export default terms;