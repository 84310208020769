//@flow
import React from 'react';
import { withRouter } from "react-router-dom";

import { compose } from "recompose";
import _get from "lodash/get";

import { connect } from "react-redux";
import { withFormik } from 'formik';
import { Persist } from "formik-persist";

import FirebaseRegisterComponent from "../../components/Register";
import { PagesContainer } from "../../components/UI/PagesContainer";
import styled from "styled-components";
import { Radio } from 'antd';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const AnimatedDiv = styled.div`
    /* height: ${props => props.show ? '200px' : 0}; */
    opacity: ${props => props.show ? 1 : 0};
    transition: 1s all ease;
`
const PageContainer = styled(PagesContainer)`
    display: 'grid';
`

const Register = ({
    auth,
    match,
    values,
    handleChange
}) => {
    console.log('render ', auth);
    return (
        <PagesContainer>
            <div style={{ width: '100%', backgroundColor: '#ccc', margin: 'auto' }}>
                    <RadioGroup
                        name='registrationType'
                        onChange={handleChange}
                        value={values.registrationType}
                    >
                        <RadioButton value="huntr">Huntr</RadioButton>
                        <RadioButton value="recruiter">Recruteur</RadioButton>
                    </RadioGroup>
                </div>
            <Persist name="register-form" />
            {/* {values.registrationType} */}
            <AnimatedDiv show={!!values.registrationType}>
                <FirebaseRegisterComponent redirectTo={`${match.url}/huntrz`} />
            </AnimatedDiv>
        </PagesContainer>
    );
};

// console.log(JSON.parse(localStorage.getItem('register-form')).values.registrationType)

const formConfig = {
    mapPropsToValues: () => ({ registrationType: _get(JSON.parse(localStorage.getItem('register-form')), 'values.registrationType', false) || '' })
}
const mapStateToProps = ({auth}) => ({auth})
const enhancer = compose(
    connect(mapStateToProps),
    withRouter,
    withFormik(formConfig)
)
export default enhancer(Register);