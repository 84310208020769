import React from 'react';

import { PagesContainer } from "../../components/UI";
import { BountyForm } from "../../components/Forms";


const NewBounty = () => {
    return (
        <PagesContainer>
            <BountyForm />
        </PagesContainer>
    );
};

export default NewBounty;