import React from 'react';
import { Mapping } from "../../commons/router";
import { Links } from "../UI";

const flex = { 
    display: "flex", 
    flexDirection: "row", 
    flexWrap: "nowrap", 
    justifyContent: "space-around", 
    alignContent: "stretch", 
    alignItems: "center" 
}

const index = () => {
    return (
        <div style={{ ...flex, width: '100%', height: 65, backgroundColor: '#37474F' }}>
            <div>
                <Links to={Mapping.legal.pathname}>
                    Mentions Légales
                </Links>
            </div>
            <div>
                {/* <Links>
                    Mentions Légales
                </Links> */}
            </div>
        </div>
    );
};

export default index;