const initialState = {};


export default function (state = initialState, action) {
    console.log('received user', action);
    switch (action.type) {
        case 'value':
            return { ...action.payload };
        default:
            return state;
    }

}