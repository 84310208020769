import { withRouter } from "react-router-dom";

import { Mapping } from "../../commons/router";
import {
    compose,
    withPropsOnChange,
    onlyUpdateForKeys,
    lifecycle,
    withStateHandlers,
} from "recompose";

import _debounce from "lodash/debounce";
import _find from "lodash/find";
import _get from "lodash/get";

const isDynamicPath = pathname => !!_get(_find(Mapping, r => r.pathname === pathname), 'hasDynamicHeader', false);

// TODO: handle lastScrollPos on page change
const enhancer = compose(
    withRouter,
    withPropsOnChange(
        ['location'],
        (ownerProps) => ({
            isDynamic: isDynamicPath(_get(ownerProps, 'location.pathname', 'default')),
        })
    ),
    withStateHandlers(
        ({ isDynamic, lastScrollPos = window.scrollY }) => ({ isPlain: !isDynamic, lastScrollPos }), {
            setPlainState: (state, props) => () => {
                if (!props.isDynamic) return ({ isPlain: true })
                const currentScroll = window.scrollY;
                if ((state.lastScrollPos <= 149 && currentScroll > 149) ||
                    (state.lastScrollPos >= 150 && currentScroll < 150)) {
                    return ({ isPlain: currentScroll >= 150, lastScrollPos: currentScroll })
                }
            }
        }
    ),
    lifecycle({
        componentDidMount() {
            this.props.setPlainState()
            window.addEventListener('scroll', _debounce(this.props.setPlainState, 0, { leading: true, trailing: true }));
        },
        componentWillUnmount() {
            window.addEventListener('scroll', _debounce(this.props.setPlainState, 0, { leading: true, trailing: true }));
        }
    }),
    onlyUpdateForKeys(['isPlain', 'location'])
)
export default enhancer;