// import * as firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
    apiKey: "AIzaSyBDCwzs8-hC0SH5QrGdoqwpF45PzTRAOj8",
    authDomain: "hunrtrz-frontend.firebaseapp.com",
    databaseURL: "https://hunrtrz-frontend.firebaseio.com",
    projectId: "hunrtrz-frontend",
    storageBucket: "hunrtrz-frontend.appspot.com",
    messagingSenderId: "1089735639114"
};


if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

// Auth init
const auth = firebase.auth();

// Sign up
const doCreateUserWithEmailAndPassword = (email, password) => auth.createUserWithEmailAndPassword(email, password);
// Sign In
const doSignInWithEmailAndPassword = (email, password) => auth.signInWithEmailAndPassword(email, password);
const doSignOut = () => auth.signOut();

const ref = firebase.firestore();
ref.settings({
    timestampsInSnapshots: true
});

export const Auth = {
    auth,
    doCreateUserWithEmailAndPassword,
    doSignInWithEmailAndPassword,
    doSignOut,
}
export const DB = {
    ref
}



