import { HomePage, Survey, Register, NewBounty } from "../../scenes";

import { Legal, Terms, Privacy } from "../../static";

const Mapping = {
    home: {
        pathname: '/',
        exact: true,
        hasDynamicHeader: true,
        component: HomePage
    },
    register: {
        pathname: '/register',
        exact: false,
        hasDynamicHeader: false,
        component: Register
    },
    newBounty: {
        pathname: '/bounty/new',
        exact: false,
        hasDynamicHeader: false,
        component: NewBounty
    },
    survey: {
        pathname: '/survey',
        exact: false,
        hasDynamicHeader: false,
        component: Survey
    },
    legal: {
        pathname: '/legal',
        exact: false,
        hasDynamicHeader: false,
        component: Legal
    },
    privacy: {
        pathname: '/privacy',
        exact: false,
        hasDynamicHeader: false,
        component: Terms
    },
    terms: {
        pathname: '/terms',
        exact: false,
        hasDynamicHeader: false,
        component: Privacy
    },
}


export default Mapping;