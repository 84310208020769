import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/app';
import { Auth, DB } from "../../commons/firebase";
import _get from "lodash/get";

const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ],
    callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            const { isNewUser } = authResult.additionalUserInfo;
            const { uid } = authResult.user;
            if (isNewUser && uid) {
                const registrationForm = JSON.parse(localStorage.getItem('register-form'));
                DB.ref.collection(`/users`).doc(uid).set({
                    user_type: _get(registrationForm, 'values.registrationType', null),
                    updated_at: new Date()
                }, { merge: true })
            }
            return false;
         }
    }
};

const Register = ({
    redirectTo
}) => (
        <div>
            <StyledFirebaseAuth 
            uiConfig={{ ...uiConfig, signInSuccessUrl: redirectTo ? redirectTo : uiConfig.signInSuccessUrl }} 
            firebaseAuth={Auth.auth} 
            />
        </div>
    );

export default Register;