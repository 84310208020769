import React from 'react';

import { 
    compose, 
    withStateHandlers, 
    lifecycle,
} from "recompose";

import _debounce from "lodash/debounce";

import CallToAction from '../../components/UI/CallToAction';
import { Mapping } from "../../commons/router";

import { 
    // HeroImgContainer,
    HeroImgFullContainer,
    HeroContent,
    // SecondSection,
 } from "./UI";

// import HowItWorks from "./HowItWorks";




// const HomePages = ({
//     windowW
// }) => (
//             <div style={{  minHeight: '100vh', backgroundColor: 'transparent' }}>
//                 <div style={{ height: '80vh', backgroundColor: 'transparent' }}>
//                     <HeroImgContainer>
//                     { windowW < 800 ? 
//                         (<img src="/assets/background_h.jpg" alt="" /> ) : 
//                         (<img src="/assets/background_w.jpg" alt="" /> )
//                         }
//                     </HeroImgContainer>
//                     <HeroContent>
//                         <h1>Hot <span className="underline">dev</span> Wanted</h1>
//                             <p style={{maxWidth: '600px'}}>Faites recruter votre dev par d'autres dev</p>
//                             <CallToAction to={'RouteMapping.newBounty'}> Déposer un bounty</CallToAction>
//                     </HeroContent>
//                 </div>
//                     <SecondSection>
//                         <HowItWorks />
//                         <CallToAction to={'RouteMapping.newBounty'}>Déposer un bounty</CallToAction>
//                     </SecondSection>
//             </div>
// );

const SurveyHomepage = ({
    windowW
}) => (
    <div style={{ minHeight: '100vh', backgroundColor: 'transparent' }}>
        <div style={{ height: '100vh', backgroundColor: 'transparent' }}>
            <HeroImgFullContainer>
                {windowW < 800 ?
                    (<img src="/assets/background_h.jpg" alt="" />) :
                    (<img src="/assets/background_w.jpg" alt="" />)
                }
            </HeroImgFullContainer>
            <HeroContent>
                    <h1>Looking for devs ?</h1>
                {/* <h1>Hot <span className="underline">dev</span> wanted</h1> */}
                <p style={{ maxWidth: '600px' }}></p>
                <CallToAction to={Mapping.survey.pathname}>Faites connaitre vos besoins</CallToAction>
            </HeroContent>
        </div>
        {/* <SecondSection>
            <HowItWorks />
            <CallToAction to={'RouteMapping.newBounty'}>Déposer un bounty</CallToAction>
        </SecondSection> */}
    </div>
)

const enhancer = compose(
    withStateHandlers(
    ({ initialWidth = window.innerWidth }) => ({ windowW: initialWidth })
    ,{
        updateWidowW: () => () => ({ windowW: window.innerWidth })
    }),
    lifecycle({
        componentDidMount() {
            window.addEventListener('resize', _debounce(this.props.updateWidowW, 400, {
                leading: true,
                trailing: true
            }));
        },
        componentWillUnmount() {
            window.removeEventListener('resize', _debounce(this.props.updateWidowW, 400, {
                leading: true,
                trailing: true
            }));
        }
    })
)

export default enhancer(SurveyHomepage);