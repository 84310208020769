import styled from "styled-components";

export const SecondSection = styled.div`
    /* min-height: 80vh; */
    height: 100%;
    background-color: #fafafa;
    padding: 150px 0;
    text-align: center;
    
`;
export const HeroImgContainer = styled.div`
    background: linear-gradient( 109.6deg,  rgba(36,45,57,1) 11.2%, rgba(16,37,60,1) 51.2%, rgba(0,0,0,1) 98.6% );
    max-height: 80vh;
    height: 80vh;
    overflow: hidden;
    position: fixed;
    z-index: -100;
    & > img {
        opacity: 0.2;
        width: 100vw;
        min-width: 100vw;
        min-height: 100%;
        z-index: -10;
    }
`;
export const HeroImgFullContainer = styled.div`
    background: linear-gradient( 109.6deg,  rgba(36,45,57,1) 11.2%, rgba(16,37,60,1) 51.2%, rgba(0,0,0,1) 98.6% );
    max-height: 100vh;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    z-index: -100;
    & > img {
        opacity: 0.2;
        width: 100vw;
        min-width: 100vw;
        min-height: 100%;
        z-index: -10;
    }
`;

export const HeroContent = styled.div`
    background-color: 'transparent';
    margin: 0 0;
    padding: 0 0;
    height: 80vh;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: space-around;
    align-items: center;
    color: white;
    & > h1 {
        color: white;
        padding: 0 0;
        margin: 50px 10px;
        @media screen and (min-width: 425px) {
            font-size: 4rem;
        }
    }
    & > p {
        color: white;
        padding: 0 0;
        margin: 60px 10px;
        @media screen and (min-width: 425px) {
            font-size: 2rem;
        }
    }

`;
