import React from 'react';
import { Link } from "react-router-dom";
import Proptypes from "prop-types";

import { 
    compose,
    setPropTypes,
    defaultProps
} from "recompose";

const types = {
    dark: '#424242',
    light: '#fff'
}
const Links = ({
    type,
    children,
    ...props,
}) => {
    return (
        <Link
        style={{
            textDecoration: 'none',
            color: types[type]
        }}
        {...props}
        >
            {children}
        </Link>
    );
};

const withProps = compose(
    setPropTypes({
        type: Proptypes.string,
    }),
    defaultProps({
        type: 'light',
        to: '/'
    })
)

export default withProps(Links);
