import React from 'react';

import { FormsUI } from "../../UI";

import { withFormik, FieldArray } from "formik";
import {
    compose
} from "recompose";

import findLastIndex from "lodash/findLastIndex";
import Button from "antd/lib/button";

import { Persist } from 'formik-persist';

const Register = ({
    setFieldValue,
    handleChange,
    handleSubmit,
    values,
    errors
}) => {
    return (
        <div style={{ backgroundColor: '#cca', padding: 20, height: '100%' }}>
            <FormsUI.InputContainer>
                <FormsUI.Input
                    placeholder='Développeur @Company, Étudiant @School'
                    name="occupation"
                    value={values.occupation}
                    onChange={handleChange}
                />
                {errors.occupation && <div id="feedback">{errors.occupation}</div>}
            </FormsUI.InputContainer>
            <FieldArray 
                name="networks"
                render={arrayHelper => (
                    <div>
                        {values.networks.map((v,i) => (
                            <FormsUI.InputContainer key={i}>
                                <FormsUI.Input
                                    placeholder='Université de ...'
                                    name={`networks[${i}]`}
                                    value={values.networks[i]}
                                    onChange={e => {
                                        const { value } = e.target;

                                        if (value.length > 0 && value.length < 2) {
                                            arrayHelper.push('')
                                        }
                                        if (value.length === 0) {
                                            arrayHelper.remove(findLastIndex(values.networks, ''))
                                        }
                                        setFieldValue(`networks[${i}]`, value)
                                    }}
                                />
                                {errors.networks && errors.networks[i] && <div id="feedback">{errors.networks[i]}</div>}
                            </FormsUI.InputContainer>
                        ))}
                    </div>
                )} 
            />
            <Button onClick={handleSubmit}>Next</Button>
            <Persist name="HuntrzSignup" />
        </div>
    );
};



export default compose(
    withFormik({
        mapPropsToValues: () => ({
            occupation: '',
            networks: ['']
        }),
        handleSubmit: (values, { setSubmitting }) => {
            console.log(values);
        } 
    })
)(Register);