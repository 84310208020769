import React from 'react';

import { Link } from "react-router-dom";

import Button from "antd/lib/button";

const CTAColor = '#88CC05';
// background - image: linear - gradient(110.8deg, rgba(86, 238, 225, 1) 11.4 %, rgba(176, 255, 39, 1) 84.5 % );
const CallToAction = ({
    to,
    children,
    ...props
}) => {
    return (
        <Link to={to}>
            <Button {...props} style={{
                minWidth: 240,
                fontSize: 17,
                fontWeight: 'bold',
                backgroundColor: CTAColor,
                color: 'white',
                borderColor: CTAColor
            }}>{children}
            </Button>
        </Link>
    );
};

export default CallToAction;